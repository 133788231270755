import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { amountFormat } from '../../helpers/Helper';
import { GET_DASHBOARD_DATA } from '../../helpers/url_helper';
import Loader from './../../component/Loader';


const Dashboard = props => {
    let history = useHistory();

    const [HomeData, SetHomeData] = useState({});
    const [isLoader, setLoader] = useState(0);

    useEffect(async () => {
        setLoader(0);
        var res_data = await post(GET_DASHBOARD_DATA, {});
        if (res_data.status) {
            SetHomeData(res_data.data);
            setLoader(1);
        } else {
            toast.error(res_data.message);
            setLoader(1);
            history.push("/users");
        }
    }, [])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tap Review | Dashboard</title>
            </Helmet>
            {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <h5>Welcome To Tap Review</h5>
                </div>
            </div>
            <div className='row mt-15'>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Active Subscription</h6>
                            <h6>{HomeData.active_user}</h6>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Cancelled Subscription</h6>
                            <h6>{HomeData.cancelled_user}</h6>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Sales</h6>
                            <h6>{amountFormat(HomeData.total_sales)}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(Dashboard);
