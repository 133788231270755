import React, { useState, useEffect, useRef } from 'react';
import {withRouter } from "react-router-dom";
import {post,get} from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import {POST_ADD_EDIT_CITIES,POST_GET_CITIES_DETAIL,POST_GET_CITIES_FORM_OPTION,GET_STATE} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import {Helmet} from "react-helmet";
import { Link,useHistory } from "react-router-dom";

const ChangePassword = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({'city_id' : 0,'country_id' : '','state_id' : '','city_name':''});
    const [title, setTitle] = useState('Add City');
    const [, forceUpdate] = useState();
    const [StateListe, SetStateListe] = useState();
    const [CountryListe, SetCountryListe] = useState();

    useEffect(() => {
        async function get_detaile () {
            await get_form_option();
            if(props.match.params.id){
                setTitle('Edit City');
                var res_data = await post(POST_GET_CITIES_DETAIL,{'city_id' : props.match.params.id},{});
                if (res_data.status) {
                    setInputs(res_data.detail);

                    var state_data = await get(GET_STATE+'?country_id='+res_data.detail.country_id);
                    if(state_data.status){
                        SetStateListe(state_data.state_list);
                    }
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_form_option = async () => {
        var res_data = await get(POST_GET_CITIES_FORM_OPTION);
        if (res_data.status) {
            SetCountryListe(res_data.country_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

     // form submit event
     const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(POST_ADD_EDIT_CITIES,form_inputs,{});
            if(res_data.status){
                toast(res_data.message);
                history.push("/cities");
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

     // input text change handler
    const handleInputChange = async (event) => {
        event.persist();
        if(event.target.name == 'country_id'){
            form_inputs.country_id = event.target.value;
            form_inputs.state_id = '';
            setInputs(form_inputs);

            var state_data = await get(GET_STATE+'?country_id='+event.target.value);
            if(state_data.status){
                SetStateListe(state_data.state_list);
            } 
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tap Review | {title}</title>
            </Helmet>
            
            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3"> {form_inputs.country_id}
                                    <label className="form-label text-capitalize">Select country</label>
                                    <select name="country_id"  className="form-control" value={form_inputs.country_id} onChange={handleInputChange}>
                                        <option value="">Select country</option>
                                        {CountryListe && Object.entries(CountryListe).map(([key, value]) => ( 
                                            <option value={value.value}>{value.label}</option>
                                        ))}
                                    </select>
                                    {simpleValidator.current.message('country', form_inputs.country_id, 'required')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Select state</label>
                                    <select name="state_id"  className="form-control" value={form_inputs.state_id} onChange={handleInputChange}>
                                        <option value="">Select State</option>
                                        {StateListe && Object.entries(StateListe).map(([key, value]) => ( 
                                            <option value={value.value}>{value.label}</option>
                                        ))}
                                    </select>
                                    {simpleValidator.current.message('states', form_inputs.state_id, 'required')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">City Name</label>
                                    <input name="city_name" value={form_inputs.city_name} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('city name', form_inputs.city_name, 'required')}
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/cities" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>    
    );
}
export default withRouter(ChangePassword)
