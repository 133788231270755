import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_USERS, GET_USERS_FORM_OPTION } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ businessName: '', categoryId: '', firstName: '', lastName: '', email: '', password: '', tc: '', isFreeAccess: 0 });
    const [title, setTitle] = useState('Add User');
    const [, forceUpdate] = useState();
    const [PasswordShow, SetPasswordShow] = useState(false);
    const [CPasswordShow, SetCPasswordShow] = useState(false);
    const [CategoryList, SetCategoryList] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            await get_form_option();
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_form_option = async () => {
        var res_data = await get(GET_USERS_FORM_OPTION);
        if (res_data.status) {
            SetCategoryList(res_data.data.category_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            if (form_inputs.password) {
                form_inputs.password = form_inputs.password;
                form_inputs.c_password = form_inputs.c_password;
            }
            var res_data = await post(POST_ADD_EDIT_USERS, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/users");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == 'isFreeAccess') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // GeneratePassword
    const GeneratePassword = () => {
        var generator = require('generate-password');
        var password = generator.generate({
            length: 10,
            numbers: true,
            symbols: false,
            uppercase: false,
            excludeSimilarCharacters: false
        });
        setInputs(inputs => ({ ...form_inputs, ['password']: password }));
    }

    const PasswordShowHide = (name) => {
        console.log(name);
        if (name === 'password') {
            if (PasswordShow) {
                SetPasswordShow(false);
            } else {
                SetPasswordShow(true);
            }
        } else {
            if (CPasswordShow) {
                SetCPasswordShow(false);
            } else {
                SetCPasswordShow(true);
            }
        }
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tap Review | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='form-group mb-3'>
                                            <label>Business Name</label>
                                            <input type='text' name="businessName" value={form_inputs.businessName} onChange={handleInputChange} className="form-control" />
                                            {simpleValidator.current.message('business name', form_inputs.businessName, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Business Category</label>
                                            <select className="form-control" name="categoryId" value={form_inputs.categoryId} onChange={handleInputChange}>
                                                <option value="">Select Category</option>
                                                {CategoryList && Object.entries(CategoryList).map(([key, value]) => (
                                                    <option value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('category', form_inputs.categoryId, 'required')}
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-3'>
                                                    <label className=''>First Name</label>
                                                    <input type='text' name="firstName" value={form_inputs.firstName} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('first name', form_inputs.firstName, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='form-group mb-3'>
                                                    <label className=''>Last Name</label>
                                                    <input type='text' name="lastName" value={form_inputs.lastName} onChange={handleInputChange} className="form-control" />
                                                    {simpleValidator.current.message('last name', form_inputs.lastName, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label className="form-label text-capitalize mt-1">Is Free Access</label>
                                                </div>
                                                <div className="col-8">
                                                    <label className="switch">
                                                        <input name="isFreeAccess" onChange={handleInputChange} defaultChecked={parseInt(form_inputs.isFreeAccess)} type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group mb-3'>
                                            <label className='text-white'>Email</label>
                                            <input type='text' name="email" value={form_inputs.email} onChange={handleInputChange} className="form-control" />
                                            {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Password</label>
                                            <div className='row'>
                                                <div className='col-sm-8'>
                                                    <div className="input-group">
                                                        <input name="password" value={form_inputs.password} onChange={handleInputChange} className="form-control" type={PasswordShow ? 'text' : 'password'} />
                                                        <div className="input-group-text pt-1" onClick={PasswordShowHide.bind(this, 'password')}>
                                                            {!PasswordShow && <i className="fa fa-eye align-middle"></i>}
                                                            {PasswordShow && <i className="fa fa-eye-slash"></i>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <button type="button" onClick={GeneratePassword} className="btn btn-primary">Generate Password</button>
                                                </div>
                                            </div>
                                            {!form_inputs.userId && simpleValidator.current.message('password', form_inputs.password, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Confirm Password</label>
                                            <div className='row'>
                                                <div className='col-sm-8'>
                                                    <div className="input-group">
                                                        <input name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="form-control" type={CPasswordShow ? 'text' : 'password'} />
                                                        <div className="input-group-text pt-1" onClick={PasswordShowHide.bind(this, 'c_password')}>
                                                            {!CPasswordShow && <i className="fa fa-eye align-middle"></i>}
                                                            {CPasswordShow && <i className="fa fa-eye-slash"></i>}
                                                        </div>
                                                    </div>
                                                    {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/users" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(AdminForm)
