import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { GET_DASHBOARD_DATA, GET_PAYMENT, GET_PAYMENT_DETAIL } from '../../helpers/url_helper';
import { getToken, amountFormat } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import Logo from './../../assets/img/logo.svg';
import Loader from './../../component/Loader';

const Payment = props => {
    let history = useHistory();
    const [PaymentDetail, SetPaymentDetail] = useState({});
    const [isLoader, setLoader] = useState(1);
    const [TotalSales, setTotalSales] = useState(0);
    const [TodayTotalSales, setTodayTotalSales] = useState(0);

    useEffect(async () => {
        get_list();
        var res_data = await post(GET_DASHBOARD_DATA, {});
        if (res_data.status) {
            setTotalSales(res_data.data.total_sales);
            setTodayTotalSales(res_data.data.total_tody_sales);
        }
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_PAYMENT,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [9, 10] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "invoiceId" },
                    { "data": "date" },
                    { "data": "name" },
                    { "data": "email" },
                    { "data": "phoneNumber" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(amountFormat(data.amount), td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(amountFormat(data.shippingCharge), td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(amountFormat(data.vat), td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(amountFormat(data.totalAmount), td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.paymentStatus === 'Pending' && <button className="border-0 badge badge-danger pt-1 pb-1" >Pending</button>}
                                {data.paymentStatus === 'Complete' && <button className="border-0 badge badge-success pt-1 pb-1"  >Complete</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={getPaymentDetail.bind(this, data.invoiceId)} className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const getPaymentDetail = async (invoiceId) => {
        setLoader(0);
        var res_data = await post(GET_PAYMENT_DETAIL, { invoiceId: invoiceId });
        if (res_data.status) {
            SetPaymentDetail(res_data.data);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        setLoader(1);
    }
    return (
        <>
            {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Payment History</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'></div>
                                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                                    <div className='card'>
                                        <div className="card-body text-center">
                                            <h6>Total Sales</h6>
                                            <h6>{amountFormat(TotalSales)}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                                    <div className='card'>
                                        <div className="card-body text-center">
                                            <h6>Today Sales</h6>
                                            <h6>{amountFormat(TodayTotalSales)}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Date</td>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Phone Number</td>
                                            <td>Amount</td>
                                            <td>Shipping Charge</td>
                                            <td>Vat</td>
                                            <td>Total Amount</td>
                                            <td>Payment Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <img src={Logo} width={'150px'} />
                                                </div>
                                                <div className='col-6 text-right'>
                                                    <p>Invoice #{PaymentDetail.invoiceId}
                                                        {PaymentDetail.paymentStatus == 'Complete' && <span className="badge bg-success font-size-12 ms-2 ml-2">Paid</span>}
                                                        {PaymentDetail.paymentStatus == 'Pending' && <span className="badge bg-danger font-size-12 ms-2 ml-2">Pending</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="invoice-title">
                                                <p className="mb-1">Second Floor Office Suite, The White House,</p>
                                                <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i>4-10 Queens Square, Poulton Le Fylde, FY6 7BN</p>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <h6 className="font-size-16 mb-3"><b>Billed To:</b></h6>
                                                    <h5 className="font-size-15 mb-2">{PaymentDetail.user_name}</h5>
                                                    {PaymentDetail.address1 && <p className="mb-1">{PaymentDetail.address1}</p>}
                                                    {PaymentDetail.address2 && <p className="mb-1">{PaymentDetail.address2}</p>}
                                                    <p className="mb-1">{PaymentDetail.city}, {PaymentDetail.state}, {PaymentDetail.country} - {PaymentDetail.zipCode}</p>
                                                    <p className="mb-1">{PaymentDetail.email}</p>
                                                    <p>{PaymentDetail.phoneNumber}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="text-sm-end">
                                                        <div>
                                                            <h6 className="font-size-15 text-black mb-1"><b>Invoice No:</b></h6>
                                                            <p>#{PaymentDetail.invoiceId}</p>
                                                        </div>
                                                        <div className="mt-3">
                                                            <h6 className="font-size-15 mb-1"><b>Invoice Date:</b></h6>
                                                            <p>{PaymentDetail.date}</p>
                                                        </div>
                                                        <div className="mt-3">
                                                            <h6 className="font-size-15 mb-1"><b>Payment Tr Id:</b></h6>
                                                            <p>{PaymentDetail.paymentTransactionId}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>Sub Total : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(PaymentDetail.amount)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>Shipping Charges : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(PaymentDetail.shippingCharge)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>VAT {PaymentDetail.vatPercentage}% : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(PaymentDetail.vat)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>Total Amount : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(PaymentDetail.totalAmount)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Payment);
