import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { GET_ORDER_LIST, GET_ORDER_DETAIL, POST_UPDATE_ORDER_STATUS, GET_DASHBOARD_DATA } from '../../helpers/url_helper';
import { getToken, amountFormat } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import Logo from './../../assets/img/logo.svg';
import Loader from './../../component/Loader';

const Payment = props => {
    let history = useHistory();
    const [OrderDetail, SetOrderDetail] = useState({});

    const [orderStaus, SetOrderStaus] = useState('');
    const [orderStausData, SetOrderStausData] = useState({ orderId: '', orderStatus: '' });
    const [isLoader, setLoader] = useState(1);
    const [TotalCard, setTotalCard] = useState(0);


    useEffect(async () => {
        get_list();
        var res_data = await post(GET_DASHBOARD_DATA, {});
        if (res_data.status) {
            setTotalCard(res_data.data.total_card);
        }
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_ORDER_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [6, 7] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "orderId" },
                    { "data": "date" },
                    { "data": "name" },
                    { "data": "email" },
                    { "data": "phoneNumber" },
                    { "data": "totalCards" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <div onClick={() => { SetOrderStaus(data.orderStatus); SetOrderStausData({ orderId: data.orderId, orderStatus: data.orderStatus }); }} data-toggle="modal" data-target="#UpdateStatusModal">
                                    {data.orderStatus === 'Cancelled' && <button className="border-0 badge badge-danger pt-1 pb-1" >Cancelled</button>}
                                    {data.orderStatus === 'Complete' && <button className="border-0 badge badge-success pt-1 pb-1"  >Complete</button>}
                                    {data.orderStatus === 'Confirmed' && <button className="border-0 badge badge-info pt-1 pb-1"  >Confirmed</button>}
                                </div>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={getOrderDetail.bind(this, data.orderId)} className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const getOrderDetail = async (orderId) => {
        setLoader(0);
        var res_data = await post(GET_ORDER_DETAIL, { orderId: orderId });
        if (res_data.status) {
            SetOrderDetail(res_data.data);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        setLoader(1);
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetOrderStausData(inputs => ({ ...orderStausData, [event.target.name]: event.target.value }));
    }


    //UpdateStatus
    const UpdateStatus = async () => {
        setLoader(0);
        var res_data = await post(POST_UPDATE_ORDER_STATUS, orderStausData);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
        setLoader(1);
    }

    return (
        <>
            {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Order History</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-12 mb-4 text-center'>
                                    <h5>Total Card : {TotalCard} </h5>
                                </div>
                            </div>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Order No</td>
                                            <td>Date</td>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Phone Number</td>
                                            <td>Total Card</td>
                                            <td>Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Order Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <img src={Logo} width={'150px'} />
                                                </div>
                                                <div className='col-6 text-right'>
                                                    <p>Invoice #{OrderDetail.invoiceId}
                                                        {OrderDetail.paymentStatus == 'Complete' && <span className="badge bg-success font-size-12 ms-2 ml-2">Paid</span>}
                                                        {OrderDetail.paymentStatus == 'Pending' && <span className="badge bg-danger font-size-12 ms-2 ml-2">Pending</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="invoice-title">
                                                <p className="mb-1">Second Floor Office Suite, The White House,</p>
                                                <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i>4-10 Queens Square, Poulton Le Fylde, FY6 7BN</p>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <h6 className="font-size-15 text-black mb-1"><b>Invoice No:</b></h6>
                                                    <p>#{OrderDetail.invoiceId}</p>
                                                    <h6 className="font-size-15 mb-1"><b>Invoice Date:</b></h6>
                                                    <p>{OrderDetail.date}</p>
                                                    <h6 className="font-size-15 mb-1"><b>Payment Tr Id:</b></h6>
                                                    <p>{OrderDetail.paymentTransactionId}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6 className="font-size-16 mb-3"><b>Billed To:</b></h6>
                                                    <h5 className="font-size-15 mb-2">{OrderDetail.user_name}</h5>
                                                    {OrderDetail.address1 && <p className="mb-1">{OrderDetail.address1}</p>}
                                                    {OrderDetail.address2 && <p className="mb-1">{OrderDetail.address2}</p>}
                                                    <p className="mb-1">{OrderDetail.city}, {OrderDetail.state}, {OrderDetail.country} - {OrderDetail.zipCode}</p>
                                                    <p className="mb-1">{OrderDetail.email}</p>
                                                    <p>{OrderDetail.phoneNumber}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6 className="font-size-16 mb-3"><b>Shipping To:</b></h6>
                                                    <h5 className="font-size-15 mb-2">{OrderDetail.user_name}</h5>
                                                    {OrderDetail.deliveryAddress1 && <p className="mb-1">{OrderDetail.deliveryAddress1}</p>}
                                                    {OrderDetail.deliveryAddress2 && <p className="mb-1">{OrderDetail.deliveryAddress2}</p>}
                                                    <p className="mb-1">{OrderDetail.deliveryCity}, {OrderDetail.deliveryState}, {OrderDetail.deliveryCountry} - {OrderDetail.deliveryZipCode}</p>
                                                </div>
                                                <div className="col-sm-12">
                                                    <h6 className='m-0 mb-2'><b>Card Info</b></h6>
                                                    <table className='table table-bordered table-sm'>
                                                        <tr>
                                                            <th width="50" className='text-center'>Id</th>
                                                            <th width="200" className='text-center'>First Name</th>
                                                            <th width="200" className='text-center'>Last Name</th>
                                                            <th className='text-center'>Url</th>
                                                        </tr>
                                                        {OrderDetail.card && Object.entries(OrderDetail.card).map(([key, card]) => (
                                                            <tr key={key}>
                                                                <td className='text-center'>{card.cardId}</td>
                                                                <td>{card.firstName}</td>
                                                                <td>{card.lastName}</td>
                                                                <td>{card.url}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                </div>
                                                <div className="col-sm-8"></div>
                                                <div className="col-sm-4">
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>Sub Total : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(OrderDetail.amount)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>Shipping Charges : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(OrderDetail.shippingCharge)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>VAT {OrderDetail.vatPercentage}% : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(OrderDetail.vat)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8 text-right'>
                                                            <h6><b>Total Amount : </b></h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6>{amountFormat(OrderDetail.totalAmount)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="UpdateStatusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Order Status</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label text-capitalize">Order Status</label>
                                        <select name="orderStatus" className="form-control" value={orderStausData.orderStatus} onChange={handleInputChange}>
                                            {orderStaus === 'Pending' && <option value="Pending">Pending</option>}
                                            {(orderStaus === 'Pending' || orderStaus === 'Confirmed') && <option value="Confirmed">Confirmed</option>}
                                            {(orderStaus === 'Confirmed' || orderStaus === 'Complete') && <option value="Complete">Complete</option>}
                                            {(orderStaus === 'Confirmed' || orderStaus === 'Cancelled') && <option value="Cancelled">Cancelled</option>}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel</button>
                            <button onClick={UpdateStatus.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Payment);
