import React, { useState, useEffect, useRef } from "react"
import { useHistory, withRouter, Link } from "react-router-dom"
import { Helmet } from "react-helmet";

import Loader from '../../component/Loader';
import SimpleReactValidator from 'simple-react-validator';
import { post } from '../../helpers/api_helper';
import { POST_GET_BLOG_DETAIL, POST_ADD_EDIT_BLOG } from '../../helpers/url_helper';
import { toast } from 'react-toastify';


import CKEditor from "react-ckeditor-component";

const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [title, setTitle] = useState('Add Blog');
    const [form_inputs, setInputs] = useState({ blog_id: 0, title: '', slug: '', description: '', image: '', metaTitle: '', metaDescription: '', metaKeywords: '' });
    const [, forceUpdate] = useState();
    const [Description, setDescription] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {

                setTitle('Edit Blog');
                var res_data = await post(POST_GET_BLOG_DETAIL, { 'blogId': props.match.params.id }, {});
                if (res_data.status) {
                    await setInputs(res_data.detail);
                    await setDescription(res_data.detail.description);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.description = Description;
            var res_data = await post(POST_ADD_EDIT_BLOG, form_inputs);
            if (res_data.status) {
                toast(res_data.message);
                history.push("/blogs");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'image') {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                console.log(reader);
                setInputs(inputs => ({ ...form_inputs, ['image']: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    const handleEditorChange = (event) => {
        var newContent = event.editor.getData();
        setDescription(newContent);
    };


    if (!isLoader) {
        return (<Loader></Loader>);
    }
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Tap Review | {title}</title>
        </Helmet>
        <div className='row mt-3'>
            <div className='col-sm-12'>
                <div className='card'>
                    <div className="card-header">
                        <h6 className='m-0'>{title}</h6>
                    </div>
                    <div className='card-body'>
                        <form className="av-invalid" onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Title</label>
                                        <input name="title" value={form_inputs.title} onChange={handleInputChange} className="form-control" placeholder="Enter Title" type="text" />
                                        {simpleValidator.current.message('title', form_inputs.title, 'required')}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Url Slug</label>
                                        <input name="slug" value={form_inputs.slug} onChange={handleInputChange} className="form-control" placeholder="Enter Url Slug" type="text" />
                                        {simpleValidator.current.message('slug', form_inputs.slug, 'required')}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="form-group mb-3">
                                                <label className="form-label">Image <small className="ml-3">Accept only png, jpg and jpeg image   Size 1000px x 1000px</small></label>
                                                <input name="image" onChange={handleInputChange} className="form-control" placeholder="Select png Image" type="file" accept="image/png,image/jpg,image/jpeg" />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            {form_inputs.image_url && <img alt="Offerwall" src={form_inputs.image_url} style={{ 'maxWidth': '150px' }} />}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Meta Title </label>
                                        <input name="metaTitle" value={form_inputs.metaTitle} onChange={handleInputChange} className="form-control" placeholder="Enter Meta Title" type="text" />
                                        {/* {simpleValidator.current.message('meta title', form_inputs.metaTitle, 'required')} */}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Meta keywords</label>
                                        <input name="metaKeywords" value={form_inputs.metaKeywords} onChange={handleInputChange} className="form-control" placeholder="Enter Meta keywords" type="text" />
                                        {/* {simpleValidator.current.message('meta keywords', form_inputs.metaKeywords, 'required')} */}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Meta Description</label>
                                        <textarea name="metaDescription" value={form_inputs.metaDescription} onChange={handleInputChange} className="form-control" placeholder="Enter Meta Description" />
                                        {/* {simpleValidator.current.message('meta description', form_inputs.metaDescription, 'required')} */}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <CKEditor
                                        activeClass="p10"
                                        content={Description}
                                        events={{
                                            change: handleEditorChange
                                        }}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="text-center mt-4">
                                <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                <Link to="/blogs" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default withRouter(AdminForm)
