import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post, del } from '../../helpers/api_helper';
import { POST_USERS_CARDS, POST_USERS_SUBSCRIPTION, POST_GET_USERS_DETAIL, POST_UPDTAE_AUTO_RENEW } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import Loader from './../../component/Loader';


const ChangePassword = props => {
    let history = useHistory();

    const [UserDetail, SetUserDetail] = useState({});
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        getUserDetail();
        get_list();
    }, [props.match.params.id])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_USERS_CARDS + "?userId=" + props.match.params.id,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [4] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "cardId" },
                    { "data": "firstName" },
                    { "data": "lastName" },
                    { "data": "created_at" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.status === 'Active' && <button className="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                {data.status === 'Cancel' && <button className="border-0 badge badge-danger pt-1 pb-1" >Cancel</button>}
                            </>, td)
                        }
                    }
                ]
            });

            $('#subscriptionTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_USERS_SUBSCRIPTION + "?userId=" + props.match.params.id,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [5] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "subscriptionId" },
                    { "data": "name" },
                    { "data": "subscriptionType" },
                    { "data": "startDate" },
                    { "data": "endDate" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.status === 'Active' && <button className="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                {data.status === 'Expired' && <button className="border-0 badge badge-danger pt-1 pb-1" >Expired</button>}
                            </>, td)
                        }
                    }
                ]
            });
        });
    }

    //UpdateStatus
    const getUserDetail = async () => {
        var res_data = await post(POST_GET_USERS_DETAIL, { userId: props.match.params.id });
        if (res_data.status) {
            SetUserDetail(res_data.data);
            console.log(res_data.data.IsAutoRenewPlan);
        } else {
            toast.error(res_data.message);
            history.push("/users");
        }
        setLoader(1);
    }

     // input text change handler
     const handleInputChangeAutoRenewPlan = async (event) => {
        event.persist();
        setLoader(0);
        var res_data = await post(POST_UPDTAE_AUTO_RENEW, {IsAutoRenewPlan : (event.target.checked)? 1 :0, userId : props.match.params.id});
        if (res_data.status) {
            getUserDetail();
        } else {
            toast.error(res_data.message);
        }
    }
    
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tap Review | Users Detail</title>
            </Helmet>
            {!isLoader && <Loader />}

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Users Detail</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="users/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-sm-4">
                                    <p className="mb-0"><b>First Name :</b></p>
                                    <p>{UserDetail.firstName}</p>
                                    <p className="mb-0"><b>Last Name :</b></p>
                                    <p>{UserDetail.lastName}</p>
                                </div>
                                <div className="col-sm-4">
                                    <p className="mb-0"><b>Email :</b></p>
                                    <p>{UserDetail.email}</p>
                                    <p className="mb-0"><b>Business Name :</b></p>
                                    <p>{UserDetail.firstName}</p>
                                </div>
                                <div className="col-sm-4">
                                    <p><a href={UserDetail.website_url} target="_blank" className="border-0 badge badge-success pt-1 pb-1">Website</a></p>
                                    <p><a href={UserDetail.review_url} target="_blank" className="border-0 badge badge-success pt-1 pb-1">Review </a></p>
                                    
                                    <div className="form-group mb-3">
                                        <div className="row">
                                            <div className="col-12">
                                            <p className="mb-0"><b>Auto Renew Subscription Enable :</b></p>
                                                <label className="switch"> 
                                                    <input name="IsAutoRenewPlan" onChange={handleInputChangeAutoRenewPlan} checked={parseInt(UserDetail.IsAutoRenewPlan)} type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {UserDetail.note && <>
                                        <p className="mb-0"><b>Auto Renew Subscription Error :</b></p>
                                        <p>{UserDetail.note}</p>
                                    </>}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-lg-6">
                                    <h6><b>Subscription Info</b></h6>
                                    <table id="subscriptionTableExample" className="table table-striped ">
                                        <thead>
                                            <tr>
                                                <td>Id</td>
                                                <td>Plan Name</td>
                                                <td>Type</td>
                                                <td>Start Date</td>
                                                <td>End Date</td>
                                                <td>Status</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-6">
                                    <h6><b>Card Info</b></h6>
                                    <table id="dataTableExample" className="table table-striped ">
                                        <thead>
                                            <tr>
                                                <td>Id</td>
                                                <td>First Name</td>
                                                <td>Last Name</td>
                                                <td>Date</td>
                                                <td>Status</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
