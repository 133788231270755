import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { GET_SUBSCRIPTION, GET_SUBSCRIPTION_DETAIL } from '../../helpers/url_helper';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { getToken } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import Logo from './../../assets/img/logo.svg';

import Loader from './../../component/Loader';


const Subscription = props => {
    const [isLoader, setLoader] = useState(1);

    let history = useHistory();
    const [SubscriptionInfo, SetSubscriptionInfo] = useState({});

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_SUBSCRIPTION,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [8, 9] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "subscriptionId" },
                    { "data": "subscriptionId" },
                    { "data": "userName" },
                    { "data": "email" },
                    { "data": "name" },
                    { "data": "subscriptionType" },
                    { "data": "startDate" },
                    { "data": "endDate" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.status === 'Active' && <button className="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                {data.status === 'Expired' && <button className="border-0 badge badge-danger pt-1 pb-1"  >Expired</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={() => { get_subscription_detail(data.subscriptionId); }} className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const get_subscription_detail = async (id) => {
        setLoader(0);
        var res_data = await post(GET_SUBSCRIPTION_DETAIL, { id: id });
        if (res_data.status) {
            SetSubscriptionInfo(res_data.data);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        setLoader(1);
    }
    return (
        <>
        {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Subscription Detail</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Id</td>
                                            <td>User Name</td>
                                            <td>User Email</td>
                                            <td>Plan Name</td>
                                            <td>Subscription</td>
                                            <td>Start Date</td>
                                            <td>Expired Date</td>
                                            <td>Plan Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Subscription Detail </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row mb-2'>
                                                <div className='col-6'>
                                                    <img src={Logo} width={'150px'} />
                                                </div>
                                                <div className='col-6 text-right'>
                                                    <p>Invoice #{SubscriptionInfo.invoiceId}
                                                        {SubscriptionInfo.paymentStatus == 'Complete' && <span className="badge bg-success font-size-12 ms-2 ml-2">Paid</span>}
                                                        {SubscriptionInfo.paymentStatus == 'Pending' && <span className="badge bg-danger font-size-12 ms-2 ml-2">Pending</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="invoice-title">
                                                <p className="mb-1">Second Floor Office Suite, The White House,</p>
                                                <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i>4-10 Queens Square, Poulton Le Fylde, FY6 7BN</p>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="row">
                                                <div className="col-sm-6">
                                                        <h6 className="font-size-16 mb-3"><b>Billed To:</b></h6>
                                                        <h5 className="font-size-15 mb-2">{SubscriptionInfo.user_name}</h5>
                                                        {SubscriptionInfo.address1 && <p className="mb-1">{SubscriptionInfo.address1}</p>}
                                                        {SubscriptionInfo.address2 && <p className="mb-1">{SubscriptionInfo.address2}</p>}
                                                        <p className="mb-1">{SubscriptionInfo.city}, {SubscriptionInfo.state}, {SubscriptionInfo.country} - {SubscriptionInfo.zipCode}</p>
                                                        <p className="mb-1">{SubscriptionInfo.email}</p>
                                                        <p>{SubscriptionInfo.phoneNumber}</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="text-sm-end">
                                                        <div>
                                                            <h6 className="font-size-15 text-black mb-1"><b>Invoice No:</b></h6>
                                                            <p>#{SubscriptionInfo.invoiceId}</p>
                                                        </div>
                                                        <div className="mt-4">
                                                            <h6 className="font-size-15 mb-1"><b>Invoice Date:</b></h6>
                                                            <p>{SubscriptionInfo.orderDate}</p>
                                                        </div>
                                                        <div className="mt-4">
                                                            <h6 className="font-size-15 mb-1"><b>Payment Tr Id:</b></h6>
                                                            <p>{SubscriptionInfo.paymentTransactionId}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="py-2">
                                                <h5>Order Summary</h5>

                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap table-centered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ 'width': '70px' }}>No.</th>
                                                                <th>Item</th>
                                                                <th>Price</th>
                                                                <th>Quantity</th>
                                                                <th className="text-end" style={{ 'width': '120px' }}>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">01</th>
                                                                <td>
                                                                    <div>
                                                                        <h5 className="text-truncate mb-0 mt-0">{SubscriptionInfo.name} </h5>
                                                                        <p className="text-muted mb-0">{SubscriptionInfo.startDate} To {SubscriptionInfo.endDate}</p>
                                                                    </div>
                                                                </td>
                                                                <td>£{parseFloat(SubscriptionInfo.amount).toFixed(2)}</td>
                                                                <td>1</td>
                                                                <td>£{parseFloat(SubscriptionInfo.amount).toFixed(2)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">Sub Total</th>
                                                                <td className="text-end">£{parseFloat(SubscriptionInfo.amount).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">Shipping Charges</th>
                                                                <td className="text-end">£{parseFloat(SubscriptionInfo.shippingCharge).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">VAT {SubscriptionInfo.vatPercentage}%</th>
                                                                <td className="text-end">£{parseFloat(SubscriptionInfo.vat).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" colspan="4" className="text-right">Total Amount</th>
                                                                <td className="text-end"><b>£{parseFloat(SubscriptionInfo.totalAmount).toFixed(2)}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Subscription);
