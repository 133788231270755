import React from 'react';
import { NavLink } from "react-router-dom";
import { IsModuleAccess } from "./../helpers/Helper";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/" className="has-arrow">
                        <FeatherIcon icon="home" width="22" />
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                {(IsModuleAccess('admin') || IsModuleAccess('user') || IsModuleAccess('use_role')) && <li>
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <FeatherIcon icon="users" width="22" />
                        <span>User</span>
                    </a>
                    <ul className="collapse list-unstyled" id="homeSubmenu">
                        <li>
                            {/* {IsModuleAccess('admin') && <NavLink to="/admin">Admin User</NavLink>} */}
                            {IsModuleAccess('user') && <NavLink to="/users">User</NavLink>}
                            {/* {IsModuleAccess('use_role') && <NavLink to="/user-role">User Roles</NavLink>} */}
                        </li>
                    </ul>
                </li>}

                {IsModuleAccess('order') && <li>
                    <NavLink exact to="/order" className="has-arrow">
                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Order</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('payment') && <li>
                    <NavLink exact to="/payment" className="has-arrow">
                        <i className="fa fa-usd mr-2 ml-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Payment</span>
                    </NavLink>
                </li>}

                {IsModuleAccess('subscription') && <li>
                    <NavLink exact to="/subscription" className="has-arrow">
                        <i className="fa fa-product-hunt mr-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Subscription</span>
                    </NavLink>
                </li>}
                {IsModuleAccess('blogs') && <li>
                    <NavLink exact to="/blogs" className="has-arrow">
                        <i className="fa fa-pencil-square-o mr-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Blogs</span>
                    </NavLink>
                </li>}
                {/* {IsModuleAccess('cardes') &&  <li>
          <NavLink to="/card" >
            <i className="fa fa-credit-card mr-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
            <span>Card</span>
          </NavLink>
        </li>}
        {IsModuleAccess('themes') &&  <li>
          <NavLink exact to="/themes" className="has-arrow">
            <i className="fa fa-address-card-o mr-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
            <span>Themes</span>
          </NavLink>
        </li>}
       
        */}

                {/* <li>
          <a href="#ReportSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
            <FeatherIcon icon="bar-chart" width="22" />
            <span style={{ 'marginTop': '2px' }}>Report</span>
          </a>
          <ul className="collapse list-unstyled" id="ReportSubmenu">
            <li>
              <NavLink to="/">Order Report</NavLink>
            </li>
          </ul>
        </li> */}
                {(IsModuleAccess('country') || IsModuleAccess('state') || IsModuleAccess('setting')) && <li>
                    <a href="#SettingSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <FeatherIcon icon="settings" width="22" />
                        <span style={{ 'marginTop': '2px' }}>Setting</span>
                    </a>
                    <ul className="collapse list-unstyled" id="SettingSubmenu">
                        <li>
                            {/* {IsModuleAccess('country') && <NavLink to="/country">Country</NavLink>} */}
                            {/* {IsModuleAccess('state') && <NavLink to="/states">State</NavLink>} */}
                            {/* <NavLink to="/cities">City</NavLink> */}
                            {IsModuleAccess('setting') && <NavLink to="/setting">Setting</NavLink>}
                        </li>
                    </ul>
                </li>}


            </ul>
        </div>
    );
}