
// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"

//user
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"

// common
export const GET_STATE = "/common/get-country-based-states"
export const GET_CITY = "/common/get-states-based-city"
export const GET_DISTRIBUTOR_LIST = "/common/get-distributor"
export const GET_RETAILER_LIST = "/common/get-retailer"



//admin
export const POST_ADMIN_USER_LIST = process.env.REACT_APP_API_URL+"admin/get-all-user"
export const DELETE_ADMIN_USER = "/admin/delete"
export const POST_UPDATE_ADMIN_USER_STATUS = "/admin/update-status"
export const POST_ADD_EDIT_ADMIN_USER = "/admin/add-edit"
export const POST_GET_ADMIN_USER_DETAIL = "/admin/detail"
export const GET_ADMIN_FORM_OPTION = "/admin/get-form-option"

//Users
export const POST_USERS_LIST = process.env.REACT_APP_API_URL+"users/get-all-user"
export const DELETE_USERS = "/users/delete"
export const POST_UPDATE_USERS_STATUS = "/users/update-status"
export const POST_ADD_EDIT_USERS = "/users/add-edit"
export const POST_GET_USERS_DETAIL = "/users/detail"
export const POST_UPDTAE_AUTO_RENEW = "/users/update-auto-renew-status"
export const POST_USERS_CARDS = process.env.REACT_APP_API_URL+"users/get-user-cards"
export const POST_USERS_SUBSCRIPTION = process.env.REACT_APP_API_URL+"users/get-user-subscription"
export const GET_USERS_FORM_OPTION = "/users/get-business-category"

//user role
export const POST_ROLE_LIST = process.env.REACT_APP_API_URL+"user-roles/get-all-user-role"
export const DELETE_ROLE = "/user-roles/delete"
export const POST_UPDATE_ROLE_STATUS = "/user-roles/update-status"
export const POST_ADD_EDIT_ROLE = "/user-roles/add-edit"
export const POST_GET_ROLE_DETAIL = "/user-roles/detail"

//card
export const POST_CARD_LIST = process.env.REACT_APP_API_URL+"card/get-all-card"
export const DELETE_CARD = "/card/delete"
export const POST_UPDATE_CARD_STATUS = "/card/update-status"
export const POST_ADD_EDIT_CARD = "/card/add-edit"
export const POST_GET_CARD_DETAIL = "/card/detail"

//themes
export const POST_THEME_LIST = process.env.REACT_APP_API_URL+"theme/get-all-themes"
export const DELETE_THEME = "/theme/delete"
export const POST_UPDATE_THEME_STATUS = "/theme/update-status"
export const POST_ADD_EDIT_THEME = "/theme/add-edit"
export const POST_GET_THEME_DETAIL = "/theme/detail"


//payment
export const GET_PAYMENT = process.env.REACT_APP_API_URL+"payment/get-all-payment"
export const GET_PAYMENT_DETAIL = "/payment/get-payment-detail"
export const GET_SUBSCRIPTION = process.env.REACT_APP_API_URL+"payment/get-subscription"
export const GET_SUBSCRIPTION_DETAIL = "/payment/get-subscription-detail"
export const GET_ORDER_LIST = process.env.REACT_APP_API_URL+"payment/get-all-card-order"
export const GET_ORDER_DETAIL = "/payment/get-order-detail"
export const POST_UPDATE_ORDER_STATUS = "/payment/update-order-status"

//countries
export const POST_COUNTRI_LIST = process.env.REACT_APP_API_URL+"countries/get-all-countries"
export const DELETE_COUNTRI = "/countries/delete"
export const POST_UPDATE_COUNTRI_STATUS = "/countries/update-status"
export const POST_ADD_EDIT_COUNTRI = "/countries/add-edit"
export const POST_GET_COUNTRI_DETAIL = "/countries/detail"


//states
export const POST_STATES_LIST = process.env.REACT_APP_API_URL+"states/get-all-states"
export const DELETE_STATES = "/states/delete"
export const POST_UPDATE_STATES_STATUS = "/states/update-status"
export const POST_ADD_EDIT_STATE = "/states/add-edit"
export const POST_GET_STATE_DETAIL = "/states/detail"
export const POST_GET_STATE_FORM_OPTION = "/states/get-state-form-option"

//cities
export const POST_CITIES_LIST = process.env.REACT_APP_API_URL+"cities/get-all-city"
export const DELETE_CITIES = "/cities/delete"
export const POST_UPDATE_CITIES_STATUS = "/cities/update-status"
export const POST_ADD_EDIT_CITIES = "/cities/add-edit"
export const POST_GET_CITIES_DETAIL = "/cities/detail"
export const POST_GET_CITIES_FORM_OPTION = "/cities/get-city-form-option"

//blog
export const POST_BLOGS_LIST = process.env.REACT_APP_API_URL+"blogs/get-blog"
export const DELETE_BLOG = "/blogs/delete"
export const POST_UPDATE_BLOG_STATUS = "/blogs/update-status"
export const POST_ADD_EDIT_BLOG = "/blogs/add-edit"
export const POST_GET_BLOG_DETAIL = "/blogs/detail"
